import { useEffect, useRef } from "react"
import Header from "@/components/Header"
import DefaultLayout from "@/components/layouts/DefaultLayout"
import { WhiteSection } from "@/styled/Grid"
import SEOData from "@/components/SEOData"
import dynamic from "next/dynamic"
import TESTIMONIALS from "@/components/Testimonials/testimonials-home.json"
import { scrollToRef } from "@/helpers/scroll"
import LdJSON from "./LdJSON"

const Social = dynamic(() => import("@/components/Social"))
const AboutUs = dynamic(() => import("./AboutUs"))
const Testimonials = dynamic(() => import("@/components/Testimonials"))
const Partners = dynamic(() => import("@/components/Partners"))
const MagnetoRent = dynamic(() => import("@/components/MagnetoRent"))
const AppointmentCta = dynamic(() => import("@/components/AppointmentCta"))
const Treatments = dynamic(() => import("@/components/Treatments"))
const Articles = dynamic(() => import("@/components/Articles"))

export default function HomeScene({ posts, tratamientos }) {
  const treatmentsRef = useRef(null)
  const ctaRef = useRef(null)

  useEffect(() => {
    if (tratamientos) scrollToRef(treatmentsRef)
  }, [tratamientos])

  return (
    <DefaultLayout page="homepage">
      <SEOData
        title="Physio WOW: Centro de Fisioterapia en Barcelona y Alrededores” | Physio WOW"
        description="Physio WOW: Fisioterapeutas en Barcelona y alrededores. Tratamientos personalizados y alquiler de magnetoterapia. Reserva online o llámanos."
      />
      <LdJSON />
      <Header page="homepage" />
      <Social />
      <WhiteSection ref={treatmentsRef}>
        <Treatments page="homepage" long />
      </WhiteSection>
      <AppointmentCta ctaRef={ctaRef} pageName="Home" />
      <WhiteSection>
        <AboutUs />
        <MagnetoRent />
        <Testimonials TESTIMONIALS={TESTIMONIALS} />
        <Articles posts={posts} page="homepage" />
      </WhiteSection>
      <Partners />
    </DefaultLayout>
  )
}
